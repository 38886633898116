// import文を使ってファイルを読み込む。

import "./scss/style.scss";

// import slick from 'slick-carousel';
import lightbox from 'lightbox2';
lightbox.option({
  'disableScrolling': true,
  'fadeDuration': 10,
  'resizeDuration': 10,
  'showImageNumberLabel': false
});

import "./js/common.js";

// import "./js/jquery.cookie.js";

// import "./js/top.js";
// import "./js/post.js";
// import "./js/page.js";
import "./js/contact.js";