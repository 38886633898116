/* ready */
$(function() {
  'use strict';

  /* NATIONALITY */
  $('.mw_wp_form_confirm input[name=nationality]').each(function() {
    if (!$(this).val()) {
      $(this).parents('td').text('');
    }
  });

  /* INQUIRY FOR */
  if (getParam('inquiry') === 'embassy') {
    $('input[value="Embassy"]').prop('checked', true);
  } else if (getParam('inquiry') === 'commercial') {
    $('input[value="Commercial section"]').prop('checked', true);
  } else if (getParam('inquiry') === 'culture') {
    $('input[value="Culture section"]').prop('checked', true);
  }

  function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
});