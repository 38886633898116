/* ready */
$(function() {
  'use strict';

  /* スムーススクロール */
  $('a[href^="#"]').on('click', function() {
    // スピード（ミリ秒）
    var speed = 300;
    // アンカーの値取得
    var href = $(this).attr('href');
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を座標で取得
    var position;
    if ($(this).parent().hasClass('l-pagetop')) {
      position = target.offset().top;
    } else {
      position = target.offset().top - $('.l-header').outerHeight();
    }
    // アニメーション
    $('body,html').not(':animated').animate({scrollTop:position}, speed, 'swing');
    return false;
  });

  naviCurrent($('.l-gnavi__list > li'));

  /* sp-navi */
  $('.l-header__menu').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth < 1025) {
      $(this).toggleClass('is-open');
      if ($(this).hasClass('is-open')) {
        $('.l-gnavi').stop().slideDown(300);
      } else {
        $('.l-gnavi').stop().slideUp(300);
      }
    }
  });

  /* tel */
  $('a[href^="tel:"]').click(function() {
    var deviceWidth = window.innerWidth;

    if (deviceWidth > 1024) {
      return false;
    }
  });


  /* -- resize -- */
  $(window).resize(function() {
    var deviceWidth = window.innerWidth;

    // reset
    if (deviceWidth > 1024) {
      $('.l-header__menu').removeClass('is-open');
      $('.l-gnavi').hide();
    }
  });
});


/* function */
// naviCurrent
function naviCurrent(naviElm) {
  const currentUrl = location.pathname + location.hash;

  naviElm.each(function() {
    let link = $(this).children('a').attr('href');
    var matchLink = new RegExp('^' + link);

    if (link == currentUrl || currentUrl.match(matchLink)) {
      $(this).addClass('is-current');
    }
  });
}